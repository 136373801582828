
.main-wrapper {
    height: 100%;
    ::v-deep > .el-scrollbar__wrap {
        overflow-x: hidden;
    }
}
.bottom-btn {
    text-align: center;
    margin-bottom: 20px;
    margin-top: 50px;
}
.creating-success {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 120px;
    img {
        width: 240px;
    }
    .text {
        color: #333333;
        font-size: 24px;
        line-height: 1;
        margin: 32px 0 36px;
    }
}
/*.demo-ruleForm {*/
/*    ::v-deep .el-form-item__label {*/
/*        font-size: 16px;*/
/*        color: #333;*/
/*    }*/
/*}*/
/*.prompt-text {*/
/*    font-size: 16px;*/
/*    margin-left: 10px;*/
/*}*/
/*.number-input {*/
/*    ::v-deep .el-input__inner {*/
/*        text-align: left;*/
/*    }*/
/*}*/
