
.demo-ruleForm {
    margin: 20px 0 0 60px;
    ::v-deep .el-form-item__label {
        font-size: 16px;
        color: #333;
    }
}
.prompt-text {
    font-size: 16px;
    margin-left: 10px;
}
.number-input {
    ::v-deep .el-input__inner {
        text-align: left;
    }
}
