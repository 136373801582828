
.stepTwo-container {
    margin: 20px 160px 0;
    /*height: calc(100vh - 383px);*/
    height: calc(100vh - 423px);
    display: flex;
    flex-direction: column;
}
.grading-type {
    display: flex;
    flex-wrap: wrap;
    .name {
        line-height: 17px;
    }
    .text {
        width: 1%;
        flex: 1;
        margin-top: -10px;
    }
}
.stepTwo-box {
    margin-top: 20px;
    display: flex;
    height: 1%;
    flex: 1;
    .item-box {
        height: 100%;
        display: flex;
        flex-direction: column;
        .box-top {
            line-height: 60px;
            background: #E7F6EF;
            padding-left: 30px;
        }
        .box-content {
            border: 1px solid #eee;
            border-top: 0;
            height: 1%;
            flex: 1;
            ::v-deep .el-scrollbar__wrap {
                overflow-x: hidden;
            }
        }
    }
    .left-box {
        width: 60%;
    }
    .right-box {
        margin-left: 30px;
        width: 1%;
        flex: 1;
    }
}
.person-list {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 30px;
    padding-right: 30px;
    .person-item {
        height: 38px;
        line-height: 38px;
        background: #fff;
        border: 1px solid #DCDCDC;
        padding: 0 8px;
        margin-left: 30px;
        margin-top: 30px;
        cursor: pointer;
        position: relative;
        transition: all .3s;
        &.current {
            border-color: #2DC079;
            background: #E7F6EF;
        }
        &.noClick {
            pointer-events: none;
            cursor: not-allowed;
            opacity: 0.6;
        }
        &:hover {
            color: #2DC079;
        }
        .iconfont {
            position: absolute;
            font-size: 12px;
            line-height: 1;
            color: #707070;
            top: 0;
            right: -16px;
            transition: all .3s;
            &:hover {
                color: red;
            }
        }
    }
}
.customRadio {
    color: #666;
    font-weight: normal;
    margin-top: 10px;
    ::v-deep {
        .el-radio__input.is-checked + .el-radio__label {
            color: #666;
        }
        .el-radio__input.is-disabled+span.el-radio__label {
            color: #C0C4CC!important;
        }
    }
}
.customRadioGroup {
    .el-radio {
        color: #666;
        margin-top: 10px;
    }
    ::v-deep {
        .el-checkbox-button__inner, .el-radio {
            font-weight: normal;
        }
        .el-radio__input.is-checked + .el-radio__label {
            color: #666;
        }
    }
}
